import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import Card from '../components/Card/Card'
import ExternalPage from '../components/ExternalPage/ExternalPage'
import { SetPasswordForm } from '../components/SetPasswordForm/SetPasswordForm'
import { confirmUser } from '../loaders/user'

const Confirm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { token } = useParams()
  if (!token) {
    navigate('/login')
  }

  const { mutate: confirmUserMutation } = useMutation({
    mutationFn: ({ password }: { password: string }) =>
      confirmUser({ token, password }),
    onSuccess: () => {
      toast.error(t('confirm.notification.success'))
      navigate('/login')
    },
    onError: (error: Error) => {
      toast.error(t('confirm.notification.error', { error: error.toString() }))
    },
  })

  return (
    <ExternalPage title={t('confirm.title')}>
      <Card>
        <SetPasswordForm
          onSubmit={(password) => confirmUserMutation({ password })}
          requireTerms={true}
        />
      </Card>
    </ExternalPage>
  )
}

export default Confirm
