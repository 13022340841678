import httpClient from '../utils/httpClient'
import { getCategories } from './categories'

export const getRecords = async () => {
  const response = await httpClient.get(`/record/load`)

  return response.data
}

export const bulkDelete = (ids) => {
  return Promise.all(ids.map((id) => httpClient.delete(`/record/${id}/delete`)))
}

export const createRecord = (data) => {
  return httpClient.post(`/record/create`, data)
}

export const uploadRecordsFile = (file) => {
  const formData = new FormData()
  formData.append('file', file)

  return httpClient.post(`/record/upload`, formData)
}

export const getBuildingCategoryRecords = async (buildingId, categoryId) => {
  const response = await httpClient.get(
    `/record/building/${buildingId}/category/${categoryId}/load`
  )

  return response.data
}

export const getRecordsWithoutBuilding = async (categoryId) => {
  const response = await httpClient.get(
    `/record/category/${categoryId}/load_without_building`
  )

  return response.data
}

export async function getRecordsData(buildingId, categoryId) {
  const [records, categories] = await Promise.all([
    buildingId
      ? getBuildingCategoryRecords(buildingId, categoryId)
      : getRecordsWithoutBuilding(categoryId),
    getCategories(),
  ])

  return {
    records,
    categories,
  }
}

export async function updateRecord(data) {
  const { recordId } = data

  return httpClient.patch(`/record/${recordId}`, data)
}
