import qs from 'query-string'

import httpClient from '../utils/httpClient'

export const getAllTimeFilters = async ({ includeAllOption = false } = {}) => {
  const query = {
    includeAllOption,
  }

  const url = qs.stringifyUrl({
    url: `/result/time_filters`,
    query,
  })

  const response = await httpClient.get(url)

  return response.data
}

export const getZeroProof = async () => {
  const response = await httpClient.get(`/result/zeroProof`)

  return response.data
}
