import httpClient from '../utils/httpClient'

export const getUsers = async () => {
  const response = await httpClient.get(`/user`)

  return response.data
}

export const createUser = async ({ firstName, lastName, email, role }) => {
  const response = await httpClient.post(`/user`, {
    firstName,
    lastName,
    email,
    role,
  })

  return response.data
}

export const getUser = async (userId) => {
  const response = await httpClient.get(`/user/${userId}`)

  return response.data
}

export const updateUser = async (userId, { firstName, lastName, role }) => {
  const response = await httpClient.patch(`/user/${userId}`, {
    firstName,
    lastName,
    role,
  })

  return response.data
}

export const deleteUser = async (userId) => {
  const response = await httpClient.delete(`/user/${userId}`)

  return response.data
}

export const loginUser = async ({ email, password }) => {
  const response = await httpClient.post(`/user/login`, {
    email,
    password,
  })

  return response.data
}

export const confirmUser = async ({ token, password }) => {
  await httpClient.post(`/user/confirm`, {
    token,
    password,
  })
}

export const resetPassword = async ({ email }) => {
  await httpClient.post(`/user/password/reset`, {
    email,
  })
}

export const setPassword = async ({ token, password }) => {
  await httpClient.post(`/user/password`, {
    token,
    password,
  })
}

export const getSettings = async () => {
  const response = await httpClient.get(`/user/settings`)

  return response.data
}

export const updateSettings = async (settings) => {
  const response = await httpClient.patch(`/user/settings`, settings)

  return response.data
}
