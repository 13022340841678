import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Card from '../components/Card/Card'
import ExternalPage from '../components/ExternalPage/ExternalPage'
import Button from '../components/core/Button/Button'
import {
  Form,
  FormField,
  FormFieldGrid,
  FormLabel,
} from '../components/core/Form/Form'
import Input from '../components/core/Input/Input'
import { resetPassword } from '../loaders/user'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [email, setEmail] = useState('')

  const { mutate: resetPasswordMutation } = useMutation({
    mutationFn: ({ email }: { email: string }) => resetPassword({ email }),
    onSuccess: () => {
      toast.success(t('forgotPassword.notification.success'))
      navigate('/login')
    },
    onError: (error: Error) => {
      toast.error(
        t('forgotPassword.notification.error', { error: error.toString() })
      )
    },
  })

  return (
    <ExternalPage title={t('forgotPassword.title')}>
      <Card>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            resetPasswordMutation({ email })
          }}
        >
          <FormFieldGrid>
            <FormField columns={6}>
              <FormLabel>{t('forgotPassword.form.email')}</FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </FormField>
          </FormFieldGrid>

          <div className="flex justify-end">
            <Button type="submit" variant="primary">
              {t('forgotPassword.form.submit')}
            </Button>
          </div>
        </Form>
      </Card>
    </ExternalPage>
  )
}
export default ForgotPassword
