import { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Button from '../core/Button/Button'
import { Form, FormField, FormFieldGrid, FormLabel } from '../core/Form/Form'
import Input from '../core/Input/Input'
import { Switch, SwitchGroup, SwitchLabel } from '../core/Switch'

const MIN_PASSWORD_LENGTH = 8

export const matchPasswords = (password: string, confirmPassword: string) => {
  return (
    password.length >= MIN_PASSWORD_LENGTH &&
    confirmPassword.length >= MIN_PASSWORD_LENGTH &&
    password === confirmPassword
  )
}

interface SetPasswordFormProps {
  requireTerms?: boolean
  onSubmit: (password: string) => void
}

export const SetPasswordForm = ({
  requireTerms,
  onSubmit,
}: SetPasswordFormProps) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)

  const canSubmit = useMemo(
    () =>
      matchPasswords(password, confirmPassword) &&
      (!requireTerms || acceptedTerms),
    [password, confirmPassword, acceptedTerms]
  )

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      await onSubmit(password)
    },
    [password, confirmPassword, acceptedTerms]
  )

  return (
    <Form onSubmit={handleSubmit}>
      <FormFieldGrid>
        <FormField columns={6}>
          <FormLabel>{t('setPasswordForm.form.password')}</FormLabel>
          <Input
            id="password"
            name="password"
            type="password"
            autoComplete="new-password"
            minLength={MIN_PASSWORD_LENGTH}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormField>
        <FormField columns={6}>
          <FormLabel>{t('setPasswordForm.form.confirmPassword')}</FormLabel>
          <Input
            id="confirm-password"
            name="confirm-password"
            type="password"
            autoComplete="new-password"
            minLength={MIN_PASSWORD_LENGTH}
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </FormField>

        <FormField columns={6}>
          {requireTerms && (
            <div className="flex items-start">
              <SwitchGroup as="div" className="flex justify-left">
                <Switch
                  enabled={acceptedTerms}
                  onChange={() => setAcceptedTerms(!acceptedTerms)}
                  aria-label={t('setPasswordForm.form.acceptTerms')}
                ></Switch>
                <SwitchLabel as="span" className="ml-3 text-sm">
                  <Trans
                    i18nKey="setPasswordForm.form.acceptTerms"
                    components={{
                      termsLink: (
                        <Link
                          to="/static/terms-of-service.pdf"
                          className="text-brand-700 hover:text-brand-600"
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                      privacyLink: (
                        <Link
                          to="/static/privacy-policy.pdf"
                          className="text-brand-700 hover:text-brand-600"
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                    }}
                  />
                </SwitchLabel>
              </SwitchGroup>
            </div>
          )}
        </FormField>
      </FormFieldGrid>

      <div className="flex justify-end">
        <Button type="submit" variant="primary" disabled={!canSubmit}>
          {t('setPasswordForm.form.submit')}
        </Button>
      </div>
    </Form>
  )
}
