import { Building } from '../models'
import httpClient from '../utils/httpClient'
import { fromJsonApi } from '../utils/jsonapi'

export const getBuildingsLegacy = async () => {
  const response = await httpClient.get(`/building/load`)

  return response.data
}

export const getBuildings = async () => {
  const response = await httpClient.get(`/building`)

  return fromJsonApi<Building[]>(response.data)
}

export const updateBuilding = (data: { buildingId: number }) => {
  return httpClient.patch(`/building/${data.buildingId}/update`, data)
}

export const createBuilding = (data: Partial<Building>) => {
  return httpClient.post(`/building/create`, data)
}

export const bulkDelete = (ids: number[]) => {
  return Promise.all(
    ids.map((id) => httpClient.delete(`/building/${id}/delete`))
  )
}

export const uploadBuildingsFile = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return httpClient.post(`/building/upload`, formData)
}
